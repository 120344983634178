import { ILibraryTopology, IRegistryAPI } from '@wix/editor-elements-registry/thunderbolt'

// const CACHE_MAX_AGE = 1000 * 60 * 60

export function createRegistryInstanceCache<TInstance extends IRegistryAPI<any>>() {
	// const instances = new LRUCache({
	// 	maxAge: CACHE_MAX_AGE,
	// })
	const cache = new Map<string, TInstance>()

	return {
		async getRegistryAPI({
			libraries,
			factory,
			shouldCache = true,
		}: {
			libraries: Array<ILibraryTopology>
			factory: () => Promise<TInstance>
			shouldCache?: boolean
		}) {
			const key = JSON.stringify(libraries)

			const withLocalURLs = libraries.some((library) => {
				return library.url?.includes('localhost')
			})

			if (!withLocalURLs && shouldCache) {
				if (cache.has(key)) {
					return cache.get(key) as TInstance
				}
				const instance = await factory()
				cache.set(key, instance)

				return instance
			}

			return factory()
		},
	}
}
