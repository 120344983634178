import {
	registry,
	createRegistryAPI,
	ILibraryTopology,
	ICorvidRegistryAPI,
	ICorvidRegistryRuntime,
	ICorvidComponentModel,
	ICorvidComponentLoader,
} from '@wix/editor-elements-registry/corvid'
import _ from 'lodash'
import { FallbackCorvidModel } from '@wix/editor-elements-corvid-utils'
import { ComponentsRegistryError, ComponentsRegistryErrorTypes } from './errors'
import { PlatformLogger } from '@wix/thunderbolt-symbols'
import { createRegistryInstanceCache } from './createRegistryInstanceCache'

type IComponentsSDKRegistryAPI = ICorvidRegistryAPI
type IComponentSDKLoaders = Record<string, ICorvidComponentLoader>
type IComponentSDKs = Record<string, ICorvidComponentModel['sdk']['factory']>
type IComponentsRegistryPlatformRuntime = ICorvidRegistryRuntime

export {
	ILibraryTopology,
	IComponentsSDKRegistryAPI,
	IComponentsRegistryPlatformRuntime,
	IComponentSDKLoaders,
	IComponentSDKs,
}

export interface IComponentSDKLoader {
	sdkTypeToComponentTypes: Record<string, Array<string>>
	loadComponentSdks: (componentTypes: Array<string>, logger: PlatformLogger) => Promise<IComponentSDKs>
}

export interface IComponentsRegistryPlatform {
	getRegistryAPI: () => IComponentsSDKRegistryAPI
	getComponentsSDKsLoader: () => IComponentSDKLoader
}

const cache = createRegistryInstanceCache<ICorvidRegistryAPI>()

async function getRegistryAPI({
	libraries,
	mode,
}: {
	libraries: Array<ILibraryTopology>
	mode?: 'lazy' | 'eager'
}): Promise<ICorvidRegistryAPI> {
	/**
	 *  TODO: do we need module loader here for SSR?
	 */
	return cache.getRegistryAPI({
		libraries,
		shouldCache: mode !== 'lazy',
		factory: () => {
			return createRegistryAPI(registry, {
				mode,
				libraries: libraries ? libraries : [],
				globals: {
					_,
					lodash: _,
				},
			})
		},
	})
}

export async function createComponentsRegistryPlatform(options: {
	libraries: Array<ILibraryTopology>
	mode?: 'lazy' | 'eager'
}): Promise<IComponentsRegistryPlatform> {
	const registryAPI = await getRegistryAPI(options)

	const components = registryAPI.getComponents()

	const sdkTypeToComponentTypes: Record<string, Array<string>> = {}

	Object.keys(components).forEach((componentType) => {
		const loader = components[componentType]

		const key = loader.statics?.sdkType ?? componentType

		if (!sdkTypeToComponentTypes[key]) {
			sdkTypeToComponentTypes[key] = []
		}

		sdkTypeToComponentTypes[key].push(componentType)
	})

	return {
		getComponentsSDKsLoader() {
			/**
			 * Still missing features:
			 *
			 * - timeout https://jira.wixpress.com/browse/WCR-129
			 */
			return {
				sdkTypeToComponentTypes: { ...sdkTypeToComponentTypes },
				async loadComponentSdks(componentTypes, logger) {
					const [existingComponents, unexistingComponents] = _.partition(componentTypes, (componentType) =>
						registryAPI.isComponentExist(componentType)
					)

					try {
						const shouldLoadFallback = unexistingComponents.length !== 0

						const [models, fallbackSDKModule] = await Promise.all([
							registryAPI.loadComponents(existingComponents),
							shouldLoadFallback ? FallbackCorvidModel.loadSDK() : null,
						])

						const componentSDKs: IComponentSDKs = {}

						if (fallbackSDKModule) {
							unexistingComponents.forEach((componentType) => {
								componentSDKs[componentType] = fallbackSDKModule.sdk as any
							})
						}

						Object.keys(models).forEach((componentType) => {
							/**
							 * Backward compatibility since we changed the component SDK model
							 * In future should `models[componentType].sdk.factory`
							 */
							const sdk = models[componentType].sdk
							componentSDKs[componentType] =
								typeof sdk.factory === 'function' ? sdk.factory : (sdk as any)
						})

						return componentSDKs
					} catch (e) {
						const error = new ComponentsRegistryError(
							`${e instanceof Error ? e.message : e}\nSDKs that failed to load: ${unexistingComponents}`,
							ComponentsRegistryErrorTypes.COMPONENT_LOADING_ERROR
						)

						error.stack = e instanceof Error ? e.stack : error.stack

						logger.captureError(error, {
							tags: {
								editorElements: true,
								method: 'loadComponentSdks',
							},
							extra: { compTypes: componentTypes, error },
						})

						return Promise.reject(error)
					}
				},
			}
		},
		getRegistryAPI() {
			return registryAPI
		},
	}
}
